/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

import Layout from '../components/Layout';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default (props) => {
  let value = '';

  if (typeof window !== 'undefined') {
    value = atob(props['*']);
  }

  return (
    <Layout title="QR Code" subtitle="" condensed>
      <Flex>
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          size="514"
          value={value}
        />
      </Flex>
    </Layout>
  );
};
